import { Application } from '@/store/modules/application/types';
import axios from 'axios';
import * as config from '../config';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ApplicationAPI {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static async list() {
        return axios.get(config.APPLICATION_LIST);
    }

    static async retrieve(applicationId) {
        return axios.get(config.APPLICATION_RETRIEVE(applicationId));
    }

    static async create(application: Application) {
        return axios.post(config.APPLICATION_CREATE, application);
    }

    static async update(applicationId: string, application: Application) {
        return axios.patch(
            config.APPLICATION_RETRIEVE(applicationId),
            application,
        );
    }

    static async users(applicationId: string, pageNumber: number) {
        return axios.get(config.APPLICATION_USERS_LIST(applicationId), {
            params: {
                page: pageNumber,
            },
        });
    }
}

class ProfileAPI {
    static async getProfile() {
        return axios.get(config.PROFILE);
    }
}

export { ApplicationAPI, ProfileAPI };
