import Vue from 'vue';
import Vuex from 'vuex';

import ApplicationModule from './modules/application';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        ApplicationModule,
    },
});
