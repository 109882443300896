import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Base from '../layout/Base.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '',
        name: 'Base',
        component: Base,
        children: [
            {
                path: '/',
                name: 'Application',
                meta: {
                    menu: 'Application',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "application" */ '../views/Application/ApplicationList.vue'
                    ),
            },
            {
                path: '/applications/:id/users',
                name: 'ApplicationUsers',
                meta: {
                    menu: 'Application',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "applicationusers" */ '../views/Application/ApplicationUsersList.vue'
                    ),
            },
            {
                path: '/profile',
                name: 'Profile',
                meta: {
                    menu: 'Profile',
                },
                component: () =>
                    import(
                        /* webpackChunkName: "profile" */ '../views/Profile.vue'
                    ),
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
