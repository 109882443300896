export const BASE_URL = '';

// Application Routes
export const APPLICATION_LIST = `/api/applications/all/`;
export const APPLICATION_CREATE = `/api/applications/new/`;
export const APPLICATION_RETRIEVE = (applicationId) =>
    `/api/applications/${applicationId}/`;

// Profile Routes
export const PROFILE = `/api/me/`;

// Application Users
export const APPLICATION_USERS_LIST = (applicationId) =>
    `/api/applications/${applicationId}/users/`;
