import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { Application } from './types';
import { ApplicationAPI, ProfileAPI } from '../../../services';

@Module({ namespaced: true })
class ApplicationModule extends VuexModule {
    public applications: Application[] = [];
    public applicationUsers: any = {};
    public pageNumber = 0;
    public application: Application = {
        allowedOrigins: { urls: [] },
        allowedCallbackURLS: { urls: [] },
    };

    @Mutation
    public loadApplications(applications: Application[]): void {
        this.applications = applications;
    }

    @Mutation
    public setPage(pageNumber: number): void {
        this.pageNumber = pageNumber;
    }

    @Mutation
    public loadApplicationUsers(users: any): void {
        this.applicationUsers = { ...users };
    }

    @Mutation
    public setApplication(application: Application): void {
        this.application = application;
    }

    @Action
    public async setPageNumber(pageNumber): Promise<void> {
        this.context.commit('setPage', pageNumber);
    }

    @Action
    public async load(): Promise<void> {
        const response = await ApplicationAPI.list();
        try {
            if (response.data.startsWith('<html>')) {
                window.location.href = '/signin';
            }
        } catch (err) {
            console.log(err);
        }
        const applications: Application[] = response.data;
        this.context.commit('loadApplications', applications);
    }

    @Action
    public async loadUsers(applicationId: string): Promise<void> {
        const response = await ApplicationAPI.users(
            applicationId,
            this.pageNumber,
        );
        const users: any[] = response.data;
        this.context.commit('loadApplicationUsers', users);
    }

    @Action
    public async add(application: Application): Promise<Application> {
        await ApplicationAPI.create(application);
        return;
    }

    @Action
    public async retrieve(applicationId: string): Promise<Application> {
        const response = await ApplicationAPI.retrieve(applicationId);
        this.context.commit('setApplication', response.data[0]);
        return response.data[0];
    }

    @Action
    public async resetApplication(): Promise<Application> {
        const application: Application = {
            allowedOrigins: { urls: [] },
            allowedCallbackURLS: { urls: [] },
        };
        this.context.commit('setApplication', application);
        return application;
    }

    @Action
    public async updateApplicationById(params: {
        applicationId: string;
        application: Application;
    }): Promise<void> {
        const { applicationId, application } = params;
        const updatedApplication = await ApplicationAPI.update(
            applicationId,
            application,
        );
        console.log(updatedApplication);
        return;
    }

    @Action
    public async getProfile(): Promise<any> {
        const profile = await ProfileAPI.getProfile();
        return profile;
    }
}
export default ApplicationModule;
