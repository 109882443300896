



import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Logo extends Vue {
    @Prop()
    name!: string;
    
    @Prop({ default: false })
    selected!: boolean;
}
