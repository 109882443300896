
























import { Component, Vue } from "vue-property-decorator";
import Icon from "./Icon.vue";

@Component({
  components: {
    Icon
  }
})
export default class Menu extends Vue {
  get selected() {
    return this.$route.meta.menu.toLowerCase()
  }
  isSelected(name) {
    return this.$route.meta.menu.toLowerCase().startsWith(name.toLowerCase());
  }
  logout() {
    window.location.href = "/signout";
  }
}
